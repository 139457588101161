import React from "react";
import { Img, Heading } from "../../components";
import { NumericFormat } from "react-number-format";

const Hero = ({ charity }) => {
	return (
		<div className="flex flex-col justify-center w-full">
			<Img src={ charity.image } alt={ charity.name } className="w-full object-cover max-h-[300px] " />
			<div className="flex flex-col px-5 md:px-10 gap-y-3 mt-5">
				<div className="flex items-center mt-1 gap-3">
					<Heading as="p" className="!text-xl">
						{ charity.name }
					</Heading>
					<div className="border border-blue text-[10px] rounded-2xl px-2 h-fit mt-1"> { charity.category_name }</div>
				</div>
				<div className="w-full h-[6px] bg-gray-200 rounded">
					<div
						className="h-full bg-blue rounded"
						style={ { width: `${ charity.progress }%` } }
					/>
				</div>
				<Heading size="sm" as="p" className="!text-base">
					<NumericFormat value={ `${ charity.nominal_current_achievement || '0' }` } displayType={ 'text' } thousandSeparator={ true } prefix={ '$' } /> - <NumericFormat value={ `${ charity.nominal_target || '0' }` } displayType={ 'text' } thousandSeparator={ true } prefix={ '$' } />
				</Heading>
			</div>
		</div>
	);
};

export default Hero;