import { useState } from 'react';
import { Img, Heading } from "components";
import { IconX } from '@tabler/icons';

const Gallery = (props) => {
	const [selectedImage, setSelectedImage] = useState(null);

	return (
		<div className='bg-gray-100/50 w-full flex justify-center'>
			<div className="my-10 px-5 md:px-10 max-w-[940px] lg:w-[940px]">
				<Heading size="md" as="h2" className="text-center mb-5">
					Gallery overview
				</Heading>
				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
					{ (props?.data?.image_distribution ?? []).map((img, index) => (
						<div
							key={ index }
							className="relative cursor-pointer group"
							onClick={ () => setSelectedImage(img) }
						>
							<Img
								src={ img }
								alt={ `Gallery Image ${ index + 1 }` }
								className="rounded-lg object-cover w-full h-48 group-hover:opacity-80"
							/>
							<div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 bg-black bg-opacity-40 rounded-lg transition-all">
								<span className="text-white text-sm">Click to Zoom</span>
							</div>
						</div>
					)) }
				</div>

				{ selectedImage && (
					<div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
						<div className="relative">
							<img src={ selectedImage } alt="Preview" className="max-w-full max-h-screen rounded-lg p-10" />
							<button
								className="absolute top-12 right-12 text-black bg-gray-200_01 rounded-full p-1 hover:bg-blue_gray-800_01 hover:text-white-A700"
								onClick={ () => setSelectedImage(null) }
							>
								<IconX size={ 20 } />
							</button>
						</div>
					</div>
				) }
			</div>
		</div>
	);
};

export default Gallery;
