import React from "react";
import { Button, Img, Heading } from "../../components";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import PropTypes from 'prop-types';


const Foundations = ({ foundations }) => {
	return (
		<div className=" bg-gray-100 w-full flex justify-center">
			<div className="flex flex-col items-center gap-y-10 justify-center max-w-[940px] w-full lg:w-[940px] py-10 px-5 md:px-10">
				<div className="flex flex-col items-center gap-5 md:w-full">
					<Heading size="md" as="h2">
						Make a Big Impact with a Small Contribution
					</Heading>
					<Link to="/foundations">
						<Button color="red_300" className=" font-medium min-w-[200px] h-[40px]">
							Explore More Foundation
						</Button>
					</Link>
				</div>

				<div className="w-full overflow-x-auto">
					<div className="grid grid-flow-col gap-5 w-full justify-start ">
						{
							foundations.map((item, index) => (
								<div
									key={ index }
									className="flex flex-col items-start bg-white-A700 min-w-[250px] w-[250px] h-full rounded-md">
									<div className="w-full h-[150px] flex justify-center border-gray-400 border-b-[0.5px] border-solid">
										<Img
											src={ item.img_banner }
											alt="foundation"
											className="w-full h-[150px] object-contain"
										/>
									</div>
									<div className="flex flex-col justify-between h-full w-full p-4">
										<div className="flex flex-col gap-2 w-full h-full ">
											<Heading as="p" className="!text-lg">
												{ item.fullname }
											</Heading>
											<Heading
												as="p"
												className="!text-sm !font-normal !text-blue_gray-700"
												dangerouslySetInnerHTML={ {
													__html: (item.description && typeof item.description === 'string' ? item.description.substring(0, 150) + '...' : '')
												} }
											/>

										</div>
										<Link to={ `/foundations/${ item.id }` } className="flex justify-center w-full mt-3">
											<Button size="sm" className="h-[40px] mb-1 uppercase font-medium !w-[70%] !text-sm">
												Learn More
											</Button>
										</Link>
									</div>
								</div>
							))
						}
					</div>
				</div>
			</div>
		</div>
	);
};

Foundations.propTypes = {
	foundations: PropTypes.array,
};

Foundations.defaultProps = {
	foundations: []
};
export default Foundations;