import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import API from 'api/Api';
import { Text, Input, Heading } from "components";
import { useParams } from "react-router-dom";
import * as loader from "components/Loading/loading";
import Chart from "./Chart";
import Gallery from "./Galery";
import Claim from "./Claim";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'utils/quill-editor/Quill.css';
import Vendor from "./Vendor";

const DistributionOverview = () => {
	const queryParams = useParams();
	const [claims, setClaims] = useState([]);
	const [detailParent, setDetailParent] = useState();
	const [detailPeriode, setDetailPeriode] = useState();
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const limit = 10;

	const fetchClaims = async (page) => {
		loader.showLoader();
		try {
			const response = await API.get(`claim/history?limit=${ limit }&skip=${ (page - 1) * limit }&start_date=${ startDate }&end_date=${ endDate }&id_parent_distribution=${ queryParams.id }`);
			setClaims(response.data.claim_history || []);
			setTotalPages(Math.ceil(response.data.count / limit));
		} catch (error) {
			console.log(error);
		} finally {
			loader.hideLoader();
		}
	};

	const getDetail = async () => {
		loader.showLoader();
		try {
			const response = await API.get(`distribution/parent/${ queryParams.id }`);
			setDetailParent(response.data.parent_distribution_data);
			const responses = response.data.parent_distribution_data;
			const idPeriode = responses.validity === 'onetime' ? 'onetime' : queryParams.periode;
			getDetailPeriode(idPeriode);
		} catch (error) {
			console.log(error);
		} finally {
			loader.hideLoader();
		}
	};

	const getDetailPeriode = async (idPeriode) => {
		loader.showLoader();
		try {
			const response = await API.get(`distribution/${ queryParams.id }/${ idPeriode }`);
			setDetailPeriode(response.data.distribution_data_list);
		} catch (error) {
			console.log(error);
		} finally {
			loader.hideLoader();
		}
	};


	useEffect(() => {
		getDetail();
	}, []);

	useEffect(() => {
		fetchClaims(page);
	}, [page, startDate, endDate]);


	return (
		<div className="flex flex-col items-center justify-start w-full">
			<div className="relative w-full">
				<Header />
			</div>
			<div className="min-h-screen-min-topbar mt-20 flex flex-col items-center justify-center w-full relative ">
				<Heading size="md" as="h2" className="text-center mb-8 mt-5">
					Distribution overview
				</Heading>

				<Chart data={ detailParent } detailPeriode={ detailPeriode } claim={ claims } />

				<div className=" w-full flex justify-center">
					<div className="flex flex-col py-10 px-5 md:px-10 max-w-[940px] w-full lg:w-[940px]">
						<Heading size="md" className="!text-2xl">
							Description
						</Heading>
						<ReactQuill
							className="w-full !text-blue_gray-700 custom-quill"
							value={ detailPeriode && detailPeriode.desc_parent_distribution }
							readOnly={ true }
							theme="bubble"
							modules={ { toolbar: false } }
						/>
					</div>
				</div>

				<Vendor data={ detailPeriode } />

				<div className=" max-w-[940px] lg:w-[940px] w-full">
					<div className="flex flex-col gap-2 py-10 px-5 md:px-10 w-full">
						<div className="flex flex-col gap-2 md:flex-row md:items-center justify-between w-full">
							<Heading size="md" className="!text-2xl">
								Claim History
							</Heading>
							<div className="flex space-x-5 items-center">
								<div className="w-full">
									<Text as="p" className="!text-blue_gray-700 text-xs">Start Date</Text>
									<Input
										color="gray_100_02"
										variant="fill"
										name="start_date"
										type="date"
										className='w-full'
										placeholder='dd/mm/yyyy'
										value={ startDate }
										onChange={ (e) => setStartDate(e.target.value) }
									/>
								</div>
								<div className="w-full">
									<Text as="p" className="!text-blue_gray-700 text-xs">End Date</Text>
									<Input
										color="gray_100_02"
										variant="fill"
										className='w-full'
										name="end_date"
										type="date"
										placeholder='dd/mm/yyyy'
										value={ endDate }
										onChange={ (e) => setEndDate(e.target.value) }
									/>
								</div>
							</div>
						</div>
						<Claim data={ claims } setPage={ setPage } page={ page } totalPages={ totalPages } />
					</div>
				</div>
				{
					detailPeriode && detailPeriode.distribution_galery.image_distribution !== null &&
					<Gallery data={ detailPeriode.distribution_galery } />
				}
			</div>
			<Footer />
		</div>
	);
};

export default DistributionOverview;
