import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Institutions from "pages/Institutions";
import DetailCharities from "pages/DetailCharities";
import DetailInstitutions from "pages/DetailInstitutions";
import ContactUs from "pages/ContactUs";
import Register from "pages/Auth/Register";
import Login from "pages/Auth/Login";
import ForgotPassword from "pages/Auth/ForgotPassword";
import ResetPassword from "pages/Auth/ResetPassword";
import Profile from "pages/Profile/Profile";
import EditProfile from "pages/Profile/EditProfile";
import ChangePassword from "pages/Profile/ChangePassword";
import TransactionList from "pages/Transaction";
import KycVerification from "pages/Profile/KycVerification";
import FoundationsPage from "pages/Foundations";
import DetailFoundationsPage from "pages/DetailFoundations";
import Payment from "pages/PaymentGateway";
import HowToDonate from "pages/HowToDonate";
import DistributionOverview from "pages/DistributionOverview";
import DistributionPage from "pages/Distribution";
import ChooseAuthMethod from "pages/Auth/ChooseAuthMethod";



const ProjectRoutes = () => {
	let element = useRoutes([
		{ path: "*", element: <NotFound /> },
		{
			path: "/",
			element: <Home />,
		},
		{
			path: "institutions",
			element: <Institutions />,
		},
		{
			path: "institutions/:id",
			element: <DetailInstitutions />,
		},
		{
			path: "charities/:id",
			element: <DetailCharities />,
		},
		{
			path: "foundations",
			element: <FoundationsPage />
		},
		{
			path: "foundations/:id",
			element: <DetailFoundationsPage />
		},
		{
			path: "contact-us",
			element: <ContactUs />,
		},
		{
			path: "register",
			element: <Register />,
		},
		{
			path: "login",
			element: <Login />,
		},
		{
			path: "forgot-password",
			element: <ForgotPassword />,
		},
		{
			path: "reset-password",
			element: <ResetPassword />,
		},
		{
			path: "profile",
			element: <Profile />,
		},
		{
			path: "edit-profile",
			element: <EditProfile />,
		},
		{
			path: "change-password",
			element: <ChangePassword />,
		},
		{
			path: "kyc-verification",
			element: <KycVerification />,
		},
		{
			path: "transactions",
			element: <TransactionList />,
		},
		{
			path: "payment",
			element: <Payment />,
		},
		{
			path: "how-to-donate",
			element: <HowToDonate />,
		},
		{
			path: "distribution/:id/:periode",
			element: <DistributionOverview />,
		},
		{
			path: "distributions",
			element: <DistributionPage />,
		},
		{
			path: "authentication",
			element: <ChooseAuthMethod />,
		},
	]);

	return element;
};

export default ProjectRoutes;
