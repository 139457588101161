import React, { useState, useEffect } from "react";
import { Button, Img, Heading } from "../../components";
import { Link } from "react-router-dom";
import API from 'api/Api';
import * as loader from "components/Loading/loading";

const Distribution = () => {
	const [distribution, setDistribution] = useState([]);

	const fetchDistribution = async () => {
		loader.showLoader();
		try {
			const response = await API.get(`distributions/byperiode?limit=4&skip=0`);
			setDistribution(response.data.distributions || []);
		} catch (error) {
			console.log(error);
		} finally {
			loader.hideLoader();
		}
	};
	useEffect(() => {
		fetchDistribution();
	}, []);

	return (
		<>
			{
				distribution && distribution.length > 0 &&
				<div className=" w-full flex justify-center">
					<div className="flex flex-col items-center gap-y-10 justify-center max-w-[940px] w-full lg:w-[940px] py-10 px-5 md:px-10">
						<div className="flex flex-col md:flex-row justify-between items-center gap-5 md:w-full">
							<Heading size="md" as="h2">
								Distribution overview
							</Heading>
							<Link to="/distributions">
								<Button className=" text-xs md:text-sm font-medium px-3 h-9 !bg-blue_gray-800/70 !rounded-3xl md:!rounded-md text-white-A700">
									View More
								</Button>
							</Link>
						</div>

						<div className="w-full overflow-x-auto">
							<div className="grid grid-flow-col gap-5 w-full justify-start mb-2">
								{
									distribution.map((item, index) => (
										<Link
											key={ index }
											to={ `/distribution/${ item.id_parent_distribution }/${ item.id_periode_distribution }` }
											className="flex flex-col items-start min-w-[300px] w-[300px] h-full rounded-md bg-white-A700 border-2 p-4 gap-2">
											<div className="w-full flex justify-center border-gray-400 border-solid">
												<Img
													src={ item.image_parent_distribution }
													alt="distribution"
													className="w-full h-[120px] rounded-md object-cover"
												/>
											</div>
											<div className="">
												<Heading as="p" className="!text-base">
													{ item.name_parent_distribution } - { item.distribution_data_periode.name_periode_distribution }
												</Heading>
												<p className="!text-xs text-gray-400">By : { item.institutions_name }</p>
											</div>
										</Link>
									))
								}
							</div>
						</div>
					</div>
				</div>
			}
		</>
	);
};

export default Distribution;
