import { useState } from 'react';
import { IconChevronLeft, IconChevronRight } from "@tabler/icons";

const Claim = (data, setPage, page, totalPages) => {
	const [expandedRows, setExpandedRows] = useState([]);
	console.log('data', data.data);
	const toggleRow = (id) => {
		setExpandedRows((prev) =>
			prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
		);
	};

	const handlePageChange = (newPage) => {
		if (newPage >= 1 && newPage <= totalPages) {
			setPage(newPage);
		}
	};

	const maskName = (name) => {
		if (!name || name.length <= 2) return name;

		const firstChar = name[0];
		const lastChar = name[name.length - 1];
		const masked = '*'.repeat(name.length - 2);

		return `${ firstChar }${ masked }${ lastChar }`;
	};
	const formatDateToDDMMYYYY = (dateStr) => {
		const [year, month, day] = dateStr && dateStr.split('-');
		return `${ day }-${ month }-${ year }`;
	};
	return (
		<div>
			<div className="overflow-x-auto">
				<table className="w-full border-collapse border border-gray-200">
					<thead>
						<tr className="bg-gray-100">
							<th className="border p-2">#</th>
							<th className="border p-2">Receiver</th>
							<th className="border p-2">Date of Claim</th>
							<th className="border p-2">Received Items</th>
							<th className="border p-2">*</th>
						</tr>
					</thead>
					<tbody>
						{ data.data.length === 0 ? (
							<tr>
								<td colSpan="5" className="text-center p-4">No data found</td>
							</tr>
						) : (
							data.data.map((claim, index) => (
								<>
									<tr key={ claim.id } className="text-start border text-gray-800 text-sm">
										<td className="border p-2 text-center">{ index + 1 }</td>
										<td className="border p-2">{ maskName(claim.ub_data.name) }</td>
										<td className="border p-2">{ formatDateToDDMMYYYY(claim.claim_data.claimed_date) }</td>
										<td className="border p-2">
											{ claim.voucher_applicable_product_data
												.map(product => `${ product.name_product } ${ product.qty_product } ${ product.satuan_product }`)
												.join(', ') }
										</td>
										<td className="flex justify-center items-center py-2">
											<button
												className={ `${ expandedRows.includes(claim.id) && 'rotate-90' } inline-flex text-sm font-semibold rounded-lg text-blue-600 hover:text-blue-800` }
												onClick={ () => toggleRow(claim.id) }
											>
												<IconChevronRight size={ 18 } />
											</button>
										</td>
									</tr>
									{ expandedRows.includes(claim.id) && (
										<tr>
											<td colSpan="5" className="px-6 py-4 text-sm text-gray-800 bg-gray-50">
												<div className="flex flex-col gap-2">
													<div><strong>Receiver:</strong> { maskName(claim.ub_data.name) }</div>
													<div><strong>Received Items:</strong> 	{ claim.voucher_applicable_product_data
														.map(product => `${ product.name_product } ${ product.qty_product } ${ product.satuan_product }`)
														.join(', ') }</div>
													<div><strong>Date:</strong> { claim.claim_data.claimed_date }</div>
													<div><strong>Source:</strong> { claim.source }{ claim.source === 'Collection' && ` - ${ claim.collection_data.name }` }</div>
													<div><strong>Institution:</strong> { claim.institutions_data.name }</div>
													<div><strong>Vendor:</strong> { claim.vendor_data.name }</div>
												</div>
											</td>
										</tr>
									) }
								</>
							))
						) }
					</tbody>
				</table>
			</div>

			<div className="flex justify-center mt-4 space-x-2">
				<button
					onClick={ () => handlePageChange(page - 1) }
					disabled={ page === 1 }
					className="px-1.5 py-1.5 bg-gray-100 rounded disabled:opacity-50"
				>
					<IconChevronLeft />
				</button>
				<span className="px-4 py-2">{ page }</span>
				<button
					disabled={ page === totalPages }
					onClick={ () => handlePageChange(page + 1) }
					className="px-1.5 py-1.5 bg-gray-100 rounded  disabled:opacity-50"
				>
					<IconChevronRight />
				</button>
			</div>
		</div>
	);
};

export default Claim;
