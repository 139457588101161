const USER_KEY = process.env.REACT_APP_USER_KEY;
const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY;
const REFRESH_KEY = process.env.REACT_APP_REFRESH_KEY;
const DATA_KEY = process.env.REACT_APP_DATA_KEY;

const Cache = {
	getUser,
	setUser,
	getAccessToken,
	setAccessToken,
	getRefreshToken,
	setRefreshToken,
	isLoggedIn,
	clearLogin,
	getData,
	setData,
};

function getUser() {
	const user = localStorage.getItem(USER_KEY);
	if (user === 'undefined' || user === undefined || user === null) {
		return null;
	}
	return JSON.parse(localStorage.getItem(USER_KEY));
}

function setUser(object) {
	localStorage.setItem(USER_KEY, JSON.stringify(object));
	setAccessToken(object.token);
	setRefreshToken(object.refresh_token);
}

function getAccessToken() {
	return localStorage.getItem(TOKEN_KEY);
}

function setAccessToken(object) {
	localStorage.setItem(TOKEN_KEY, object);
}

function getRefreshToken() {
	return localStorage.getItem(REFRESH_KEY);
}

function setRefreshToken(object) {
	localStorage.setItem(REFRESH_KEY, object);
}

function isLoggedIn() {
	return getAccessToken() != null && getAccessToken() !== '';
}

function clearLogin() {
	localStorage.removeItem(USER_KEY);
	localStorage.removeItem(TOKEN_KEY);
	localStorage.removeItem(REFRESH_KEY);
	deleteCookie(DATA_KEY);
	console.log('Cleared login cache and cookie');
}

function getData() {
	const data = getCookie(DATA_KEY);
	if (data === 'undefined' || data === null) {
		return null;
	}
	return JSON.parse(data);
}

function setData(object, days = 1) {
	try {
		setCookie(DATA_KEY, JSON.stringify(object), days);
		return true;
	} catch (error) {
		console.error('Error saving to cookie:', error);
		return false;
	}
}

function setCookie(name, value, days) {
	const expires = new Date(Date.now() + days * 864e5).toUTCString();
	document.cookie = `${ encodeURIComponent(name) }=${ encodeURIComponent(value) }; expires=${ expires }; path=/`;
}

function getCookie(name) {
	const cookieString = document.cookie.split('; ').find(row => row.startsWith(`${ encodeURIComponent(name) }=`));
	return cookieString ? decodeURIComponent(cookieString.split('=')[1]) : null;
}

function deleteCookie(name) {
	document.cookie = `${ encodeURIComponent(name) }=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
}


export default Cache;
