import React from "react";
import { Button, Img, Heading } from "../../components";
import { Link, useLocation } from "react-router-dom";
import { NumericFormat } from "react-number-format";

const Institutions = ({ institutions }) => {
	const locations = useLocation();
	const getLocations = locations.pathname.split('/')[1];
	return (
		<div className="justify-center w-full gap-y-10 gap-x-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid ">
			{
				institutions.map((item, index) => (
					<div key={ index } className="flex flex-col justify-between w-full h-full bg-white-A700 border-2 rounded-xl" >
						<Link to={ getLocations === 'institutions' ? `/charities/${ item.id }` : `/foundations/${ item.id }` } >
							<div>
								<div className="flex flex-row justify-center w-full border-gray-400 border-b-[0.5px] border-solid">
									<Img
										src={ getLocations === 'institutions' ? item.image : item.img_banner }
										alt={ getLocations === 'institutions' ? item.charity_name : item.fullname }
										className="w-full  h-[150px] object-contain"
									/>
								</div>
								<div className="flex flex-col justify-between h-full w-full p-3 gap-2">
									<div className="flex items-center gap-x-3">
										<Heading as="p" className="!text-lg">
											{ getLocations === 'institutions' ? item.name : item.fullname }
										</Heading>
										{ getLocations === 'institutions' && <div className="mt-1 border border-blue text-[10px] rounded-2xl px-2 h-fit"> { item.category_name }</div> }
									</div>
									{ getLocations === "institutions" && (
										<div className="w-full h-[6px] bg-gray-200 rounded">
											<div
												className="h-full bg-blue rounded"
												style={ { width: `${ item.progress }%` } }
											/>
										</div>
									) }
									{ getLocations === 'institutions' &&
										<Heading size="sm" as="p" className="!text-base">
											<NumericFormat value={ `${ item.nominal_current_achievement || '0' }` } displayType={ 'text' } thousandSeparator={ true } prefix={ '$' } /> - <NumericFormat value={ `${ item.nominal_target || '0' }` } displayType={ 'text' } thousandSeparator={ true } prefix={ '$' } />
										</Heading> }
									{ getLocations === 'foundations' &&
										<Heading as="p" className="!text-sm !font-normal !text-blue_gray-700" dangerouslySetInnerHTML={ { __html: (item.description && typeof item.description === 'string' ? item.description.substring(0, 150) + '...' : '') } } /> }
								</div>
							</div>
						</Link>
						<Link to={ getLocations === 'institutions' ? `/charities/${ item.id }` : `/institutions/${ item.id }` } className="flex flex-col items-center my-3">
							<Button size="sm" className="h-[40px] mb-1 uppercase font-medium  !w-[60%] !text-sm">
								{ getLocations === 'institutions' ? 'Donate' : 'View More' }
							</Button>
						</Link>
					</div>
				))
			}
		</div>
	);
};

export default Institutions;