import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import { LoaderContainer } from "react-global-loader";
import { Loading } from "components/Loading";
import API from 'api/Api';
import Cache from 'utils/cache';
import * as loader from "components/Loading/loading";

function App() {
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const urlToken = decodeURIComponent(urlParams.get('xtoken'));
		const platform = decodeURIComponent(urlParams.get('platform'));

		if (urlToken && urlToken !== 'null') {
			getProfile(urlToken, platform);
		} else {
			const cachedToken = Cache.getAccessToken();
			if (cachedToken && cachedToken !== 'null') {
				getTokenCheck(cachedToken);
			}
		}
	}, []);

	const getProfile = (token, platform) => {
		loader.showLoader();
		API.getUseToken('aix/profile', token).then(response => {
			const profileData = response.data;
			const newToken = profileData.token;

			Cache.setUser(profileData);
			Cache.setAccessToken(newToken);
			Cache.setData({ token: newToken, platform });

			getTokenCheck(newToken);
			loader.hideLoader();
		}).catch(error => {
			if (error.response) {
				Cache.clearLogin();
			}
			loader.hideLoader();
		});
	};

	const getTokenCheck = (token) => {
		loader.showLoader();
		API.get('token-check', token).then(response => {
			loader.hideLoader();
		}).catch(error => {
			if (error.response) {
				const status = error.response.status;
				if (status === 500 || status === 401) {
					Cache.clearLogin();
				}
			}
			loader.hideLoader();
		});
	};

	return (
		<Router>
			<Routes />
			<LoaderContainer hideDuration={ 3000 } autoHide>
				<Loading />
			</LoaderContainer>
		</Router>
	);
}

export default App;

