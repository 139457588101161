import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Institutions } from "layout/Institutions";
import API from 'api/Api';
import 'react-loading-skeleton/dist/skeleton.css';
import { Heading, Img } from "components";
import { useParams } from "react-router-dom";
import * as loader from "components/Loading/loading";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'utils/quill-editor/Quill.css';

export default function DetailFoundationsPage() {
	const queryParams = useParams();
	const [foundations, setFoundations] = useState(null);
	const [foundationsDetail, setFoundationsDetail] = useState(null);
	const [page, setPage] = useState(1);

	useEffect(() => {
		getAPIInstitutions();
	}, [page]);

	useEffect(() => {
		getFoundationsDetails();
	}, []);

	const getAPIInstitutions = () => {
		loader.showLoader();
		API.get(`institutions?limit=0&page=0&filter=&foundation_id=${ queryParams.id }`)
			.then(e => {
				loader.hideLoader();
				setFoundations(e.data.institutions);
			})
			.catch(e => {
				loader.hideLoader();
			});
	};
	const getFoundationsDetails = () => {
		loader.showLoader();
		API.get(`foundations?foundation_id=${ queryParams.id }`)
			.then(e => {
				loader.hideLoader();
				setFoundationsDetail(e.data.foundations);
			})
			.catch(e => {
				loader.hideLoader();
			});
	};

	const onPrevClick = () => {
		if (page > 1) {
			setPage(prevPage => prevPage - 1);
		}
	};

	const onNextClick = () => {
		setPage(prevPage => prevPage + 1);
	};
	const [isExpanded, setIsExpanded] = useState(false);

	const description = foundationsDetail && foundationsDetail[0].description;

	const handleReadMoreClick = () => {
		setIsExpanded(!isExpanded);
	};
	const truncatedDescription = description && description.length > 800 ? description.substring(0, 800) + '...' : description;

	return (
		<div className="flex flex-col items-center justify-start w-full bg-white-A700 relative">
			<div className="relative w-full">
				<Header />
			</div>
			{ foundationsDetail && (
				<div className="mt-14 md:mt-16 w-full flex flex-col justify-center items-center">
					<div className="relative  h-[250px] lg:h-[350px] w-full">
						<div className="absolute w-full h-[250px] lg:h-[350px] top-0 bg-black opacity-70 z-1" />
						<Img
							src={ foundationsDetail && foundationsDetail[0].img_banner }
							alt="foundations image"
							className="w-full z-0 object-cover h-[250px] lg:h-[350px]"
							priority
						/>
						<div className="mx-auto absolute inset-0 flex items-center justify-center w-full  z-2 px-10 lg:w-[940px]">
							<Heading
								as="p"
								size="xl"
								className=" !text-white-A700 text-center"
							>
								{ foundationsDetail && foundationsDetail[0].fullname }
							</Heading>
						</div>
					</div>
					<div className=" bg-white-A700 w-full flex justify-center">
						<div className=" max-w-[940px] w-full md:w-[940px]  relative px-5 md:px-10 py-10">
							{ foundationsDetail && foundationsDetail[0]?.fullname && (
								<Heading as="p" size="lg" className="font-semibold text-black/80 mb-4">
									{ foundationsDetail[0].fullname }
								</Heading>
							) }
							<div className="bg-white-A700 border shadow-md rounded-lg p-3 lg:p-6">
								<ReactQuill
									className="w-full !text-blue_gray-700 custom-quill"
									value={ isExpanded ? description : truncatedDescription }
									readOnly={ true }
									theme="bubble"
									modules={ { toolbar: false } }
								/>

								{ description && description.length > 800 && (
									<button
										onClick={ handleReadMoreClick }
										className="text-blue mt-2 text-center w-full font-medium hover:text-blue/40"
									>
										{ isExpanded ? "Read Less" : "Read More" }
									</button>
								) }
							</div>
						</div>
					</div>
					{ foundations && foundations.length > 0 && (
						<div className=" w-full flex justify-center relative bg-gradient-to-t from-blue/50 via-blue/25 to-white-A700">
							{/* <Img
								src='/images/banner_organisasi.jpeg'
								alt="foundations image"
								className="z-0 object-cover w-full h-[530px]"
							/>

							<div className="absolute  w-full h-[530px] top-0 bg-black opacity-50 z-1" /> */}
							<div className="max-w-[940px] w-full lg:w-[940px] py-10 px-5 md:px-10">
								<Heading
									as="p"
									size="lg"
									className="!text-black"
								>
									Institution
								</Heading>
								<div className="bg-blue h-[3px] w-10 mb-8 mt-3" />
								<Institutions
									institutions={ foundations }
									onPrevClick={ onPrevClick }
									onNextClick={ onNextClick }
								/>
							</div>
						</div>
					) }
				</div>

			) }
			<Footer />
		</div>
	);
}
