import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const ChooseAuthMethod = () => {
	const currentUrl = window.location.href;
	const redirectUrlAix = currentUrl.includes('stage')
		? 'https://stage-fe.aix-exchange.com/'
		: 'https://aix.asia/';
	const redirectUrlPortal = currentUrl.includes('stage')
		? 'https://stage-portal.sedeqahtech.org/'
		: 'https://portal.sedeqahtech.org/';

	const handleRedirect = (type) => {
		if (type === "portal") {
			window.location.href = redirectUrlPortal;
		} else if (type === "donate-aix") {
			window.location.href = redirectUrlAix;
		}
	};

	return (
		<div className="flex flex-col items-center justify-start w-full bg-white-A700 relative">
			<div className="relative w-full z-50">
				<Header />
			</div>
			<div className="min-h-screen-min-topbar mt-10  flex max-w-[940px] w-full lg:w-[940px] z-10">
				<div className="w-full lg:w-1/2 flex flex-col justify-center items-center p-8 mt-5 ">
					<div className="flex items-center mb-10 gap-5 flex-col text-center">
						<h1 className="text-2xl font-bold text-gray-800">
							Revolutionizing Zakat Payments with SedeqahTech
						</h1>
						{/* <h3 className="text-xl font-semibold text-gray-800">Welcome Back</h3> */ }
						{/* <p className="text-gray-500">Please choose how you'd like to continue:</p> */ }
					</div>

					<p className="mb-2 text-sm text-gray-500">
						Want to donate? Login or register with AIX:
					</p>
					<button
						onClick={ () => handleRedirect("donate-aix") }
						className="border py-2 rounded w-full border-blue/30 hover:text-white-A700 hover:bg-blue transition">
						Login or Register with AIX
					</button>

					<div className="flex items-center my-6 w-full">
						<hr className="flex-grow border-0.5 border-gray-300" />
						<span className="mx-2 text-gray-400">OR</span>
						<hr className="flex-grow border-0.5 border-gray-300" />
					</div>

					<p className="text-sm mb-2 text-center  text-gray-500">
						Are you an organization looking to collaborate with us? Log in or register via our Portal:
					</p>

					<div className="space-y-4 mb-6 w-full">
						<button
							onClick={ () => handleRedirect("portal") }
							className="border py-2 rounded w-full border-blue/30 hover:text-white-A700 hover:bg-blue transition">
							Login or Register to Portal
						</button>

					</div>
				</div>

				<div className="lg:flex w-1/2 items-center justify-center hidden">
					<img src="/images/img_login.png" alt="Login Illustration" className="mx-auto w-full" />
				</div>
			</div>

			<div className="bg-gradient-to-r from-blue/10 to-blue/30 w-1/2 h-full absolute  top-0 z-0 right-0 hidden lg:block" />
			<Footer />
		</div>
	);
};

export default ChooseAuthMethod;
