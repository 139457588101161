import React, { useState } from "react";
import Header from "../../components/Header";
import { Heading, Img, Text } from "components";
import Footer from "../../components/Footer";
import { IconChevronDown } from "@tabler/icons";

export default function HowToDonate() {
	const [openPanel, setOpenPanel] = useState(null);

	const togglePanel = (index) => {
		if (openPanel === index) {
			setOpenPanel(null);
		} else {
			setOpenPanel(index);
		}
	};
	const content = [
		{
			title: 'Login in AIX',
			desc: [
				{
					text: `Makesure you have an account and login in <a href="https://aix.asia/login" target="_blank" class="inline text-blue-500 underline">AIX</a>. You may refer to this document for how to <a href="https://aix.asia/faq" target="_blank" class="inline text-blue-500 underline">Register</a> and <a href="https://aix.asia/login" target="_blank" class="inline text-blue-500 underline">Login</a> in AIX.<br/><br/>Once you have logged in, click on SedeqahTech in the header or in the profile dropdown, and you will be directed to the SedeqahTech landing page.`,
					image: [
						'images/step/1.jpeg'
					]
				}
			]
		},
		{
			title: 'Foundations',
			desc: [
				{
					text: 'Feel free to read about the SedeqahTech and scrolldown until you find list of Foundations.',
					image: [
						'images/step/7.jpeg',
						'images/step/2.jpeg'
					]
				},
				{
					text: 'Click one of the Foundation to read the detail and see list of institutions under the foundation you selected.',
					image: [
						'images/step/3.jpeg',
						'images/step/4.jpeg'
					]
				}
			]
		},
		{
			title: 'Institutions',
			desc: [
				{
					text: 'Click one of the Institution you will have description about the instituion it self and list of Charities under it institution',
					image: [
						'images/step/5.jpeg',
						'images/step/6.jpeg'
					]
				},
			]
		},
		{
			title: 'Charities',
			desc: [
				{
					text: 'Click one of the Charity you will have the description about the goal of the charity, and from here you can contributes some of amount you prefer to donate, then click "Confirm".',
					image: [
						'images/step/11.jpeg',
					]
				},
				{
					text: 'You will direct to the form for donation preview. Input your Two-Factor Authentication(2FA), get the OTP send to your email and fill in the form, then click "Donate" and it will ditect to the "Donation History".',
					image: [
						'images/step/10.jpeg',
					]
				},
				{
					text: 'In this "Donation History" you will see all of the history donation you have contributed will all of the detail of each donation.',
					image: [
						'images/step/8.jpeg',
					]
				},
			]
		}
	];
	return (
		<div className="flex flex-col items-center justify-start w-full bg-[#f1f2f5]">
			<div className="relative w-full">
				<Header />
			</div>
			<div className="min-h-screen-min-topbar mt-14 max-w-[940px] w-full lg:w-[940px]">
				<Img src="images/img_image_195.png" alt="institutions image" className="w-full object-cover max-h-[400px] " />
				<Heading as="p" size="lg" className="mt-5 md:mt-10 px-10 text-center">
					How to Donate at SedeqahTech
				</Heading>
				<div className="px-5 md:px-10 py-10">
					{ content.map((item, index) => (
						<div key={ index } className="py-6 border-b border-blue_gray-100">
							<button
								className="w-full text-left text-xl font-bold flex justify-between"
								onClick={ () => togglePanel(index) }
							>
								{ item.title }
								<IconChevronDown />
							</button>
							{ openPanel === index && (
								<div className="p-4">
									{ item.desc.map((descItem, descIndex) => (
										<div key={ descIndex } className="mt-4">
											<Text size="s" as="p" className="text-black mb-3 text-justify ">
												<span dangerouslySetInnerHTML={ { __html: descItem.text } } />
											</Text>
											{ descItem.image.map((imgSrc, imgIndex) => (
												<img
													key={ imgIndex }
													src={ imgSrc }
													alt={ `step ${ imgIndex }` }
													className="w-full mb-3"
												/>
											)) }
										</div>
									)) }
								</div>
							) }
						</div>
					)) }
				</div>
			</div>
			<Footer />
		</div>
	);
}
