import React from "react";
import { Img, Heading, } from "../../components";

const Roadmap = () => {
	return (
		<div className="flex-col items-center justify-center md:py-10 px-5 md:px-10 max-w-[940px] w-full lg:w-[940px] relative">
			<Heading size="md" as="h2" className="text-center">
				How to Donate
			</Heading>
			<Img
				src="/images/img_roadmap.svg"
				alt="photo_five"
				className="justify-center py-10 md:px-10 w-full m-auto object-cover pointer-events-none"
			/>
			{/* <div className="absolute top-60 left-32 z-[2]">
				<div className="w-40">
					<Text as="p" className=" text-black">
						Registration
					</Text>
					<Text as="p" className="!text-xs text-black">
						Users sign up with basic details (name, email, password) and confirm via an email verification link.
					</Text>
				</div>
			</div>
			<div className="absolute top-60 left-[360px] z-[2]">
				<div className="w-40">
					<Text as="p" className=" text-black">
						KYC
					</Text>
					<Text as="p" className="!text-xs text-black">
						Users complete identity verification (ID, proof of address) to access platform features.
					</Text>
				</div>
			</div>
			<div className="absolute top-60 left-[600px] z-[2]">
				<div className="w-40">
					<Text as="p" className=" text-black">
						Deposit USDT
					</Text>
					<Text as="p" className="!text-xs text-black">
						Users deposit USDT by generating a wallet address or scanning a QR code.
					</Text>
				</div>
			</div>
			<div className="absolute top-[450px] left-80 z-[2]">
				<div className="w-40">
					<Text as="p" className=" text-black">
						Choose Foundation
					</Text>
					<Text as="p" className="!text-xs text-black">
						Users explore registered foundations and charities, reviewing missions and goals.
					</Text>
				</div>
			</div>
			<div className="absolute top-[450px] left-[560px] z-[2]">
				<div className="w-40">
					<Text as="p" className=" text-black">
						Choose Institution
					</Text>
					<Text as="p" className="!text-xs text-black">
						Users select a specific institution or project under a foundation for targeted donations.
					</Text>
				</div>
			</div>
			<div className="absolute top-[670px] left-[280px] z-[2]">
				<div className="w-40">
					<Text as="p" className=" text-black">
						Donate
					</Text>
					<Text as="p" className="!text-xs text-black">
						Users enter the donation amount, which is converted from cryptocurrency to fiat (if needed), and the transaction is processed.
					</Text>
				</div>
			</div>
			<div className="absolute top-[670px] left-[490px] z-[2]">
				<div className="w-40">
					<Text as="p" className=" text-black">
						Receipt & History
					</Text>
					<Text as="p" className="!text-xs text-black">
						Users receive a digital receipt and can track their donation history in their account.
					</Text>
				</div>
			</div> */}

		</div>
	);
};

export default Roadmap;