import React from "react";
import { Img, Heading, Text } from "../../components";

const WhatWeDo = () => {
	const list = [
		{
			image: 'images/img_empower_finance.png',
			title: 'Empowering Financial Inclusion',
			subTitle: 'At its core, SedeqahTech is committed to fostering financial inclusion by eliminating barriers to Sedeqah contribution. Through its intuitive interface and diverse contribution methods, the platform caters to a broad spectrum of users, ensuring that anyone, anywhere, can contribute to charitable causes with ease.'
		},
		{
			image: 'images/img_harness.png',
			title: 'Harnessing AIX Technology',
			subTitle: 'Central to SedeqahTech`s efficacy is its utilization of AIX technology, a sophisticated framework that optimizes transactions for efficiency, security, and transparency. By leveraging AI algorithms, SedeqahTech streamlines the Sedeqah contribution process, mitigating errors and enhancing the overall user experience.'
		},
		{
			image: 'images/img_crypto_integrate.png',
			title: 'Fiat & Crypto Integration',
			subTitle: 'In recognizing the evolving landscape of finance, SedeqahTech offers dual contribution options, allowing users to contribute Sedeqah in both traditional fiat currencies and popular cryptocurrencies. This innovative approach not only accommodates diverse financial preferences but also positions SedeqahTech at the forefront of digital philanthropy.'
		},
		{
			image: 'images/img_global_impact.png',
			title: 'Global Impact, Local Relevance',
			subTitle: 'While SedeqahTech operates on a global scale, its impact remains deeply rooted in local communities. By facilitating Sedeqah Contribution through a digital platform, SedeqahTech empowers individuals to support charitable initiatives that resonate with their cultural and religious values, fostering a sense of solidarity and collective responsibility.'
		}
	];
	return (
		<div className="py-10 px-5 md:px-10  max-w-[940px] w-full lg:w-[940px] bg-white-A700 flex flex-col items-center justify-start gap-2">
			<Heading size="md" as="h2">
				What We Do
			</Heading>
			<div className="justify-center w-full gap-10 md:grid-cols-2 grid-cols-1 grid mt-5">
				{
					list.map((item, index) => (
						<div key={ index } className="flex flex-col items-center justify-start w-full gap-2">
							<Img src={ item.image } alt={ item.title } className="w-[50%] object-cover order-1" />
							<Heading size="s" as="h3" className="!text-xl order-0 text-center">{ item.title }</Heading>
							<Text size="s" as="p" className="!text-gray-600 text-justify order-2">
								{ item.subTitle }
							</Text>
						</div>
					))
				}
			</div>
		</div>
	);
};

export default WhatWeDo;