import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import API from 'api/Api';
import 'react-loading-skeleton/dist/skeleton.css';
import { Img, Heading } from "components";
import { Link } from "react-router-dom";
import * as loader from "components/Loading/loading";

const DistributionPage = () => {
	const [distributions, setDistributions] = useState([]);
	const [limit] = useState(10);
	const [skip, setSkip] = useState(0);
	const [loading, setLoading] = useState(false);
	const [totalCount, setTotalCount] = useState(0);

	const fetchDistribution = async () => {
		if (loading) return;
		setLoading(true);
		loader.showLoader();

		try {
			const response = await API.get(`distributions/byperiode?limit=${ limit }&skip=${ skip }`);
			const newData = response.data.distributions || [];

			setDistributions((prev) => [...prev, ...newData]);
			setTotalCount(response.data.count || 0);
		} catch (error) {
			console.error(error);
		} finally {
			loader.hideLoader();
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchDistribution();
	}, [skip]);

	const handleLoadMore = () => {
		if (distributions.length < totalCount) {
			setSkip((prev) => prev + limit);
		}
	};

	return (
		<div className="flex flex-col items-center justify-start w-full bg-[#E5E7EB]">
			<div className="relative w-full">
				<Header />
			</div>
			<div className="min-h-screen-min-topbar mt-10 max-w-[940px] w-full lg:w-[940px] px-10 flex flex-col items-center">
				<Heading as="p" size="lg" className="mt-10">
					Distributions
				</Heading>
				{ distributions.length > 0 ? (
					<div className="justify-center w-full gap-5 grid-cols-1 md:grid-cols-2 grid py-10">
						{ distributions.map((item, index) => (
							<Link
								key={ index }
								to={ `/distribution/${ item.id_parent_distribution }/${ item.id_periode_distribution }` }
								className="flex flex-col items-start min-w-[300px] h-full rounded-md bg-white-A700 p-4 gap-2">
								<div className="w-full flex justify-center border-gray-400 border-solid">
									<Img
										src={ item.image_parent_distribution }
										alt="distribution"
										className="w-full h-[200px] rounded-md object-cover"
									/>
								</div>
								<div className="">
									<Heading as="p" className="!text-base">
										{ item.name_parent_distribution } - { item.distribution_data_periode.name_periode_distribution }
									</Heading>
									<p className="!text-xs text-gray-400">By: { item.institutions_name }</p>
								</div>
							</Link>
						)) }
					</div>
				) : (
					<p className="text-gray-500 text-center mt-10">No distributions found.</p>
				) }

				{ distributions.length < totalCount && (
					<button
						onClick={ handleLoadMore }
						className="my-6 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue_gray-100 disabled:bg-gray-400 text-center"
						disabled={ loading }
					>
						{ loading ? "Loading..." : "Load More" }
					</button>
				) }
			</div>

			<Footer />
		</div>
	);
};

export default DistributionPage;

