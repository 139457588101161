import { Img } from "components";
import { IconHeartHandshake } from "@tabler/icons";

const Chart = (props) => {
	const totalBeneficiaries = props.detailPeriode?.distribution_category.reduce((sum, distribution) => {
		return sum + distribution.total_ub;
	}, 0);
	const totalPending = totalBeneficiaries - props.claim?.length;
	return (
		<div className="mb-5 px-5 md:px-10 max-w-[940px] lg:w-[940px] ">
			<div className="border border-gray-100 w-full p-6 grid grid-cols-1 md:grid-cols-2 gap-8 rounded-lg shadow-md hover:shadow-lg transition-shadow bg-gradient-to-tl from-blue/25  via-white-A700 to-white-A700">
				<div className="flex flex-col gap-6 py-5 px-2">
					<div>
						<h2 className="text-[27px] font-bold text-gray-800">{ props.data?.name_parent_distribution || "" }</h2>
						<p className="text-sm text-gray-500 mt-2">By: { props.detailPeriode?.institutions_name || "" }</p>
						{
							props.detailPeriode?.source === 'Collection' &&
							<a href={ `/charities/${ props.detailPeriode?.collection_id }` } className="text-gray-500  text-sm">Source: <span className="underline text-blue">{ props.detailPeriode?.collection_name || "" }</span></a>
						}

					</div>
					<div className="grid grid-cols-2 gap-2">
						<div className="flex flex-col items-center">
							<div className="w-16 h-16 flex items-center justify-center bg-blue/60 rounded-full">
								<IconHeartHandshake className="text-white-A700 text-3xl" />
							</div>
							<p className="text-2xl font-bold">{ totalBeneficiaries }</p>
							<p className="text-sm text-gray-500">End-beneficiaries</p>
						</div>
						<div className="flex flex-col items-center justify-center">


							<div className="space-y-2">
								<div className="flex items-center gap-2">
									<span className="w-4 h-4 bg-green-500 rounded-sm"></span>
									<span className="text-sm text-gray-700">
										Total claimed  { props.claim?.length }
									</span>
								</div>
								<div className="flex items-center gap-2">
									<span className="w-4 h-4 bg-yellow-500 rounded-sm"></span>
									<span className="text-sm text-gray-700">
										Total pending  { totalPending }
									</span>
								</div>
							</div>
						</div>
					</div>

				</div>

				<div className="relative">
					<Img
						src={ props.detailPeriode?.image_parent_distribution }
						alt="Gallery"
						className="rounded-lg object-cover w-full h-64 md:h-full shadow-md hover:opacity-90 transition-opacity"
					/>
				</div>
			</div>
		</div>
	);
};


export default Chart;
