import React from "react";
import { Img, Heading } from "../../components";

const Vendor = ({ data }) => {
	return (
		<div className='bg-gray-100/50 w-full flex lg:justify-center py-10'>
			<div className="flex flex-col gap-3 justify-center px-5 md:px-10 max-w-[940px] lg:w-[940px]">
				<Heading size="md" className="!text-2xl">🎁 Available Packages
				</Heading>

				<div className="w-full ">
					<div className="grid grid-flow-col gap-5 w-full justify-start">
						{
							data && data.distribution_category.map((item, index) => (
								<div
									key={ index }
									className="flex flex-col items-center w-[300px] min-w-[300px] h-full rounded-2xl bg-white-A700 shadow-md border border-gray-200 p-5 gap-4 transform transition-all ">
									<div className="w-full gap-2">
										<Heading as="h3" className="text-lg font-semibold text-gray-800">
											{ item.voucher_name }
										</Heading>
										<p className="text-sm mt-1 text-gray-500">By : { item.vendor_name }</p>

										<div className="mt-2 space-y-1 text-sm text-gray-600">
											<p className="font-medium text-gray-700">Product List:</p>
											<ul className="list-disc pl-5">
												{
													item.list_voucher_data.voucher_applicable_product_data && item.list_voucher_data.voucher_applicable_product_data.map((a, index) => (
														<li >{ a.name_product } { a.qty_product }{ a.qty_satuan_product }</li>)) }
											</ul>
										</div>
									</div>
								</div>
							))
						}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Vendor;
