import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Hero, AboutUs, WhatWeDo, Foundations, Roadmap, Distribution } from "layout/Home";
import API from 'api/Api';
import Cache from "utils/cache";
import * as loader from "components/Loading/loading";

const Home1Page = () => {
	const [foundations, setFoundations] = useState([]);

	const getAPIInstitutions = () => {
		loader.showLoader();
		API.get('foundations?limit=4&page=1&filter=&is_approved=true').then(response => {
			setFoundations(response.data.foundations);
			loader.hideLoader();
		}).catch(error => {
			console.log(error);
			loader.hideLoader();
		});
	};

	useEffect(() => {
		getAPIInstitutions();
	}, []);

	return (
		<div className="flex flex-col items-center justify-start w-full bg-white-A700">
			<div className="relative w-full">
				<Header
					showBackButton={ false }
					token={ decodeURIComponent(new URLSearchParams(window.location.search).get('xtoken')) }
					platform={ decodeURIComponent(new URLSearchParams(window.location.search).get('platform')) } />
			</div>
			<Hero />
			<AboutUs />
			<WhatWeDo />
			<Roadmap />
			{ foundations && foundations.length > 0 && <Foundations foundations={ foundations } /> }
			<Distribution />
			<Footer
				platform={ decodeURIComponent(new URLSearchParams(window.location.search).get('platform')) } />
		</div>
	);
};

export default Home1Page;
